import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer-checkout',
    templateUrl: './footer-checkout.component.html',
    styleUrls: ['./footer-checkout.component.scss']
})

export class FooterCheckoutComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}