import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MailingService {
  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  private cookieLifeTime = 60;

  createContact(data: any, originFont: string, isNewBulletin = false): Observable<boolean> {
    const nameArr: string[] = data.name.trim().split(' ');
    let firstname = nameArr[0];
    let lastname = '';
    switch (nameArr.length) {
      case 2:
        lastname = nameArr[1];
        break;
      case 3:
        lastname = `${nameArr[1]} ${nameArr[2]}`;
        break;
      case 4:
        firstname = `${nameArr[0]} ${nameArr[1]}`;
        lastname = `${nameArr[2]} ${nameArr[3]}`;
        break;
    }

    const payload: any = {
      email: data.email,
      attributes: {
        APELLIDOS: !isNewBulletin ? lastname : data.lastname,
        DATA_POLICY: data.dataPolicy,
        DEPARTAMENTO_TIENDA: data.state || '',
        EMAIL: data.email,
        FECHA_NACIMIENTO: data.birthdate || '',
        FUENTE: originFont || 'API',
        NOMBRE: !isNewBulletin ? firstname : data.name,
        NUM_DOCUMENTO: data.docNumber || '',
        PRIVACY_POLICY: data.privacyPolicy,
        TIPO_DOCUMENTO: data.docType || '',
      },
      emailBlacklisted: false,
      smsBlacklisted: false,
      listIds: [33],
      updateEnabled: false,
      smtpBlacklistSender: [
        '5l5on@XCyTwNVlbFYMuZJRtYQifJ.mvm',
        'AHzfzNXj0fQ@ogSExyrBXmIaCyjopMPfNgGdxE.siws',
      ],
    };

    let url = `/api/contacts`;
    return this.http.post<boolean>(url, payload).pipe(
      map((res: any) => {
        if (res?.id) {
          this.saveContactIdInCookie(res.id);
          return true;
        }
        return false;
      })
    );
  }

  private saveContactIdInCookie(contactId: string) {
    const expirationDate = new Date();
    expirationDate.setSeconds(expirationDate.getSeconds() + this.cookieLifeTime);
    const expires = `expires="${expirationDate.toUTCString()}"`;
    if (isPlatformBrowser(this.platformId))
      document.cookie = `subscriptionId=${contactId.toString()}; ${expires}; path=/`;
  }
}
