import { Injectable } from '@angular/core';
import { TABS_DATA } from '../../../public/pages/home/constants';

export interface OptionsItem {
  id: number;
  text: string;
  link: string;
  active: string;
  tab: string;
  icon: string;
  external: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MainService {
  private readonly _menu: OptionsItem[] = TABS_DATA.map((tab, index) => ({
    id: index,
    text: tab.name,
    link: tab.path,
    active: tab.navigates ? 'active' : '',
    tab: '',
    icon: tab.icon,
    external: false,
  }));

  constructor() {}

  get menu(): OptionsItem[] {
    return this._menu;
  }
}
