import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HomeService } from '../../services';
import { ICarouselItem, IDynamicPackage } from '../../models';
import { DataPagePresenterService } from '../../../../../Services/presenter/data-page-presenter.service';
import { ConfigCatService } from '../../../../../shared/services/config-cat.service';
import { PackageCardService } from '../../../../../Services/package-card/package-card.service';

@Component({
  selector: 'app-home-sections',
  templateUrl: './home-sections.component.html',
  styleUrls: ['./home-sections.component.scss'],
})
export class HomeSectionsComponent implements OnInit, AfterViewChecked, OnDestroy {
  private _unsubscribe$ = new Subject<void>();

  carouselEventsImages: ICarouselItem[] = [];
  carouselEventsConfig: NguCarouselConfig = {
    easing: 'cubic-bezier(0, 0, 0.2, 1)',
    grid: { xs: 1.25, sm: 2, md: 3, lg: 4, xl: 4, all: 0 },
    loop: false,
    point: { visible: false, hideOnSingleSlide: true },
    slide: 1,
    speed: 800,
    touch: true,
  };

  displayPackageList: IDynamicPackage[] = [];

  constructor(
    public dataPagePresenterService: DataPagePresenterService,
    private _homeService: HomeService,
    private _configCatService: ConfigCatService,
    private readonly packageCardService: PackageCardService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.packageCardService
      .getPackageCardData()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((data) => {
        this.displayPackageList = data;
      });
    this.getBanners();
    this.getListMoreCardsFlag();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  private getListMoreCardsFlag() {
    this._configCatService
      .getCardListLimitFlag()
      .then((result) => this._configCatService.setCardListLimitFlag(result))
      .catch(() => this._configCatService.setCardListLimitFlag(false));
  }

  private getBanners() {
    this._homeService
      .getBannersHome()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((data) => {
        if (data?.result?.length > 0) {
          this.carouselEventsImages = data.result.map((item) => ({
            desktopLogo: item.imageLink,
            mobileLogo: item.mobileImageLink,
            redirectUrl: item?.redirectUrl ?? '',
            alt: '',
          }));
        }
      });
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
