import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NmvModel } from 'src/app/shared/utils';
import { ResponseModelT } from 'src/app/shared/models';
import { IFlightRates, IVuelos, TYPE_PARAM } from './flight.models';
import { map } from 'rxjs/operators';
import { IAereolineas } from 'src/app/shared/components/moleculas/aereolineas/aereolineas.interfaces';

@Injectable()
export class FlightService {
    constructor(private httpClient: HttpClient) { }

    // TODO modelo servicio

    getPasajesAereos(type:TYPE_PARAM){
        const nmvModel = new NmvModel()
        const options = {
            params: nmvModel.params.set('Parameter.Type', type)
        }
        const url = environment.urlNmviajes + '/Flight/GetMostWanted'
        return this.httpClient.get<ResponseModelT<IFlightRates[]>>(url, options).pipe(
            map(resp=> resp.Result)
        )
    }

    getAereolineas(){
        const nmvModel = new NmvModel()

        const options = {
            params: nmvModel.params.set('Parameter.Status', true)
        }
        const url = environment.urlNmviajes + '/Airline';
        return this.httpClient.get<ResponseModelT<IAereolineas[]>>(url, options).pipe(
            map(resp=> resp.Result)
        )
    }

    getVuelos(){
        const nmvModel = new NmvModel()
        const options = {
            params: nmvModel.params.set('Parameter.Status', true)
        }
        const url = environment.urlNmviajes + '/Flight/GetContinents';
        return this.httpClient.get<ResponseModelT<IVuelos[]>>(url, options).pipe(
            map(resp=> resp.Result)
        )
    }
}
