import { NgModule } from '@angular/core';
import { InputClassComponent } from './input-class.component';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule],
    exports: [ InputClassComponent],
    declarations: [
    InputClassComponent
  ],
    providers: [],
})
export class InputClassModule { }
