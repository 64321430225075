import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AerolineasComponent } from './public/pages/aerolineas/aerolineas.component';
import { CierrapuertasOfertasComponent } from './public/pages/cierrapuertas-ofertas/cierrapuertas-ofertas.component';
import { CierrapuertasComponent } from './public/pages/cierrapuertas/cierrapuertas.component';
import { CitaComponent } from './public/pages/cita/cita.component';
import { CondicionesDeReservaComponent } from './public/pages/condiciones-de-reserva/condiciones-de-reserva.component';
import { ConformidadComponent } from './public/pages/conformidad/conformidad.component';
import { DocumentacionViajeComponent } from './public/pages/documentacion-viaje/documentacion-viaje.component';
import { HomePageComponent } from './public/pages/home/pages/home-page/home-page.component';
import { HomeSectionsComponent } from './public/pages/home/pages/home-sections/home-sections.component';
import { HotSaleDetailComponent } from './public/pages/home/pages/hot-sale-detail/hot-sale-detail.component';
import { HotSalePageComponent } from './public/pages/home/pages/hot-sale-page';
import { LibroReclamacionesComponent } from './public/pages/libro-reclamaciones/libro-reclamaciones.component';
import { NuestraEmpresaComponent } from './public/pages/nuestra-empresa/nuestra-empresa.component';
import { NuestrasAgenciasComponent } from './public/pages/nuestras-agencias/nuestras-agencias.component';
import { OffersComponent } from './public/pages/offers/offers.component';
import { PoliticasComponent } from './public/pages/politicas/politicas.component';
import { ResponsabilidadSocialComponent } from './public/pages/resonsabilidad-social/responsabilidad-social.component';
import { ResultadosComponent } from './public/pages/resultados/resultados.component';
import { BenefitsComponent } from './public/pages/retail/benefits/benefits.component';
import { RetailComponent } from './public/pages/retail/retail.component';
import { TerminosCondicionesComponent } from './public/pages/terminos-condiciones/terminos-condiciones.component';
import { TusDatosComponent } from './public/pages/tus-datos/tus-datos.component';
import { DestinosComponent } from './public/pages/vuelos/commons/components/destinos/destinos.component';
import { PageNotFoundComponent } from './shared/components/moleculas/page-not-found/page-not-found.component';
import { CanActivateCheckoutGuard } from './shared/guards/checkout.guard';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    children: [
      { path: '', component: HomeSectionsComponent },
      { path: 'vuelos', component: HomeSectionsComponent },
      { path: 'hoteles', component: HomeSectionsComponent },
      { path: 'vuelohotel', component: HomeSectionsComponent },
      { path: 'paquetes', component: HomeSectionsComponent },
      { path: 'seguro-viaje', component: HomeSectionsComponent },
      { path: 'ofertas', component: HotSalePageComponent },
      { path: 'ofertas/:sectionId/:id', component: HotSaleDetailComponent },
    ],
  },
  {
    path: 'booking/v2',
    loadChildren: () =>
      import('./public/pages/booking/booking.module').then((m) => m.BookingModule),
  },
  {
    path: 'seguros',
    loadChildren: () =>
      import('./public/pages/insurance/insurance.module').then((m) => m.InsuranceModule),
  },
  {
    path: 'perfil',
    loadChildren: () => import('./private/perfil/perfil.module').then((m) => m.PerfilModule),
  },
  {
    path: 'traveloutlet',
    loadChildren: () =>
      import('./public/pages/travel-outlet/travel-outlet.module').then((m) => m.TravelOutletModule),
  },
  {
    path: 'recepcion',
    loadChildren: () =>
      import('./public/pages/tottem-digital/tottem-digital.module').then(
        (m) => m.TottemDigitalModule
      ),
  },
  {
    path: 'eventos',
    loadChildren: () =>
      import('./public/pages/eventos/eventos.module').then((m) => m.EventosModule),
  },
  {
    path: 'promociones',
    loadChildren: () =>
      import('./public/pages/promociones/promociones.module').then((m) => m.PromocionesModule),
  },
  {
    path: 'suscribirse',
    loadChildren: () =>
      import('./public/pages/subscription/subscription.module').then((m) => m.SubscriptionModule),
  },
  {
    path: 'canales-de-atencion',
    loadChildren: () =>
      import('./public/pages/canales-atencion/canales-atencion.module').then(
        (m) => m.CanalesAtencionModule
      ),
  },
  {
    path: 'resultados',
    loadChildren: () =>
      import('./public/pages/flights-results').then((m) => m.FlightsResultsModule),
  },
  { path: 'agenda-tu-cita', component: CitaComponent },
  { path: 'vuelos/resultados', component: ResultadosComponent },
  { path: 'conformidad', component: ConformidadComponent },
  { path: 'aerolineas/:code', component: AerolineasComponent },
  { path: 'terminos-condiciones', component: TerminosCondicionesComponent },
  { path: 'documentacion-viaje', component: DocumentacionViajeComponent },
  { path: 'documentacion_de_viaje.html', component: DocumentacionViajeComponent },
  { path: 'confirmacion/:id', component: HomePageComponent },
  { path: 'vuelos/:slug', component: DestinosComponent },
  { path: 'armapaquete', component: HomePageComponent },
  { path: 'autos', component: HomePageComponent },
  { path: 'actividades', component: HomePageComponent },
  { path: 'nuestra-empresa', component: NuestraEmpresaComponent },
  { path: 'nuestras-agencias', component: NuestrasAgenciasComponent },
  { path: '28-de-julio', component: RetailComponent },
  { path: 'beneficios', component: BenefitsComponent },
  { path: 'libro-reclamaciones', component: LibroReclamacionesComponent },
  { path: 'actividades', component: HomePageComponent },
  { path: 'cierrapuertas', component: CierrapuertasComponent },
  { path: 'cierrapuertas/ofertas', component: CierrapuertasOfertasComponent },
  { path: 'tusdatos', component: TusDatosComponent },
  { path: 'politicas', component: PoliticasComponent },
  { path: 'condiciones-de-reserva', component: CondicionesDeReservaComponent },
  { path: 'responsabilidad-social', component: ResponsabilidadSocialComponent },
  { path: 'beneficios', component: BenefitsComponent },
  { path: 'ofertas', component: OffersComponent },
  {
    path: 'widgets',
    loadChildren: () =>
      import('./public/pages/widgets/widgets.module').then((m) => m.WidgetsModule),
  },
  {
    path: 'tuscitas',
    loadChildren: () =>
      import('./public/pages/tuscitas/tuscitas.module').then((m) => m.TuscitasModule),
  },
  {
    path: 'booking',
    loadChildren: () =>
      import('./public/pages/checkout-page/checkout.module').then((m) => m.CheckoutPageModule),
    canActivate: [CanActivateCheckoutGuard],
  },
  {
    path:
      'booking/itinerary/:transactionId/:idGroup/:segments/:flightType/:departureLocation/:arrivalLocation/' +
      ':departureDate/:arrivalDate/:adults/:children/:infants/:flightClass',
    loadChildren: () =>
      import('./public/pages/checkout-page/checkout.module').then((m) => m.CheckoutPageModule),
  },
  {
    path:
      'booking/itinerary/:transactionId/:idGroup/:segments/:flightType/:departureLocation/:arrivalLocation/' +
      ':departureDate/:arrivalDate/:adults/:children/:infants/:flightClass/:upsellName',
    loadChildren: () =>
      import('./public/pages/checkout-page/checkout.module').then((m) => m.CheckoutPageModule),
  },
  {
    path: 'hoteles',
    loadChildren: () =>
      import('./public/pages/web-hotel/web-hotel.module').then((m) => m.WebHotelModule),
  },
  {
    path: 'paquetes',
    loadChildren: () =>
      import('./public/pages/nm-packages/nm-packages.module').then((m) => m.NmPackagesModule),
  },
  /*{
    path: 'booking/ofertas/:sectionId/:offerId/:passengersQty/:departureDate/:roomType',
    loadChildren: () => import('./public/checkout-page/checkout.module').then((m) => m.CheckoutPageModule)
  },*/
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: 'top',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
