import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FlightService } from 'src/app/api/api-nmviajes/services/flight.service';
import { Guid } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../../shared/services/storage/storage.service';
import { IIataDetail } from '../vuelos/commons/components/destinos/destino.models';
import { DestinosService } from '../vuelos/commons/components/destinos/services/destinos.service';

@Component({
  selector: 'app-flight-deals',
  templateUrl: './flight-deals.component.html',
  styleUrls: ['./flight-deals.component.scss'],
})
export class FlightDealsComponent implements OnInit, OnDestroy {
  airfare: any;
  limit: number = 4;
  unsubscribe$ = new Subject<void>();
  currentDestinos: IIataDetail[] = [];

  constructor(
    private readonly _flightService: FlightService,
    private readonly _router: Router,
    private readonly _storageService: StorageService,
    private readonly destinosService: DestinosService
  ) {}
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.getAirfare();
    this.destinosService.currentDestinos$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.currentDestinos = data;
      }
    });
  }

  getAirfare(): void {
    this.airfare = this._storageService.getLocalItemWithExpiration('mostWanted');
    if (this.airfare == null)
      this._flightService
        .v1ApiFlightGetMostWantedGet({
          TrackingCode: Guid(),
          MuteExceptions: environment.muteExceptions,
          'Caller.Company': 'Expertia',
          'Caller.Application': 'NMViajes',
        })
        .subscribe((res: any) => {
          this.airfare = JSON.parse(res).Result;
          this._storageService.setLocalItemWithExpiration('mostWanted', this.airfare, 300);
        });
  }

  viewRates(entity: any, index: number): void {
    const url = this.currentDestinos.find(
      (x) => x.originIata === 'LIM' && x.iata === entity.DestinationCode
    )?.slug;
    this._router.navigateByUrl(`/vuelos/${url}`);
  }

  viewMoreOffers(): void {
    this.limit = this.limit + 4;
  }
}
