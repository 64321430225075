export const listTypeDocument = [
	{
		value: '0',
		name: 'DNI'
	},
	{
		value: '1',
		name: 'CE'
	},
	{
		value: '2',
		name: 'PAS'
	}
];

export const listCreditCard = [
	{
		name: 'Visa',
		image: './assets/checkout/visa.png'
	},
	{
		name: 'Master Card',
		image: './assets/checkout/mastercard.png'
	},
	{
		name: 'American Express',
		image: './assets/checkout/amex.png'
	},
	{
		name: 'Dinners Club',
		image: './assets/checkout/dinner.png'
	}
];

export const listBanksInternet = [
	{
		name: 'BBVA',
		image: './assets/checkout/bbva.png'
	},
	{
		name: 'BCP',
		image: './assets/checkout/bcp.png'
	},
	{
		name: 'Interbank',
		image: './assets/checkout/interbank.png'
	},
	{
		name: 'Scotiabank',
		image: './assets/checkout/scotia.png'
	}
];

export const listAgencies = [
	{
		name: 'Caja Arequipa',
		image: './assets/checkout/caja.png'
	},
	{
		name: 'Caja Huancayo',
		image: './assets/checkout/huancayo.png'
	},
	{
		name: 'Caja Trujillo',
		image: './assets/checkout/trujillo.png'
	},
	{
		name: 'Caja Tacna',
		image: './assets/checkout/tacna.png'
	},
	{
		name: 'Western Union',
		image: './assets/checkout/union.png'
	},
	{
		name: 'Tambo',
		image: './assets/checkout/tambo.png'
	}
];

export enum CheckoutOrigin {
	NMVIAJES,
	OFFERS,
	META,
	WHATSAPP_IA
}