import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReplacePipe } from '../../../pipes/replace.pipe';
import { NewCardOfferComponent } from './new-card-offer.component';
import { CustomButtonsModule } from '../custom-buttons/custom-buttons.module';

@NgModule({
  imports: [CommonModule, CustomButtonsModule],
  exports: [NewCardOfferComponent],
  declarations: [NewCardOfferComponent, ReplacePipe],
  providers: [],
})
export class NewCardOfferModule {}
