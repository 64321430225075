import { Component, Input, OnInit } from '@angular/core';
import { ExchangeRate } from '../../../../api/api-checkout/models/rq-checkout-search';
import { IDynamicPackage } from '../../../../public/pages/home/models';

@Component({
	selector: 'app-package-card',
	templateUrl: './package-card.component.html',
	styleUrls: ['./package-card.component.scss']
})
export class PackageCardComponent implements OnInit {
	@Input() item: IDynamicPackage;

	constructor() {}

	ngOnInit(): void {}

	getExchangeAmount(value: ExchangeRate | null, currentAmount?: number): number | null {
		if (value === null || !currentAmount) return null;
		return value.amount * currentAmount;
	}
}
