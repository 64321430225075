import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvertisementModalComponent } from './advertisement-modal.component';
import { CustomButtonsModule } from '../../custom-buttons/custom-buttons.module';
import { PipesModule } from '../../../../pipes/pipes.module';

@NgModule({
  declarations: [AdvertisementModalComponent],
  imports: [CommonModule, CustomButtonsModule, PipesModule],
})
export class AdvertisementModalModule {}
