import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SEO, PATH_NAMES } from '../../../shared/constants/';
import { SeoModel } from '../../../shared/models/seo.model';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  protected _isBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _title: Title,
    private _meta: Meta
  ) {
    if (isPlatformBrowser(this._platformId)) this._isBrowser = true;
  }

  setSEOByPath(path: string): void {
    switch (path) {
      case PATH_NAMES.FLIGHTS:
        this.setSEOValues(SEO.flights);
        break;
      case PATH_NAMES.HOTELS:
        this.setSEOValues(SEO.hotels);
        break;
      case PATH_NAMES.FLIGHT_HOTEL:
        this.setSEOValues(SEO.flightAndHotel);
        break;
      case PATH_NAMES.TOURIST_PACKAGES:
        this.setSEOValues(SEO.touristPackages);
        break;
      case PATH_NAMES.TRAVEL_INSURANCE:
        this.setSEOValues(SEO.travelInsurance);
        break;
      case PATH_NAMES.POLICIES:
        this.setSEOValues(SEO.policies);
        break;
      case PATH_NAMES.BOOKING:
        this.setSEOValues(SEO.booking);
        break;
      case PATH_NAMES.RESPONSIBILITY:
        this.setSEOValues(SEO.responsability);
        break;
      case PATH_NAMES.DATE:
        this.setSEOValues(SEO.date);
        break;
      case PATH_NAMES.COMPLAINTS:
        this.setSEOValues(SEO.complaints);
        break;
      case PATH_NAMES.BENEFITS:
        this.setSEOValues(SEO.benefits);
        break;
      case PATH_NAMES.JULY:
        this.setSEOValues(SEO.july);
        break;
      case PATH_NAMES.COMPANY:
        this.setSEOValues(SEO.company);
        break;
      case PATH_NAMES.DOCUMENT_TRAVEL:
        this.setSEOValues(SEO.documentTravel);
        break;
      case PATH_NAMES.TERM_COND:
        this.setSEOValues(SEO.termCond);
        break;
      case PATH_NAMES.PROMOTION:
        this.setSEOValues(SEO.promotions);
        break;
      case PATH_NAMES.SUSCRIPTION:
        this.setSEOValues(SEO.subscriptions);
        break;
      case PATH_NAMES.EVENTS:
        this.setSEOValues(SEO.events);
        break;
      case PATH_NAMES.RECEPTION:
        this.setSEOValues(SEO.reception);
        break;
      case PATH_NAMES.TRAVEL_OUT:
        this.setSEOValues(SEO.traveloutlet);
        break;
      case PATH_NAMES.CANALES:
        this.setSEOValues(SEO.canales);
        break;
      case PATH_NAMES.AGENCIES:
        this.setSEOValues(SEO.agencies);
        break;
      default:
        this.setSEOValues(SEO.home);
        break;
    }
  }

  setSEOValues(seoData: SeoModel) {
    this._title.setTitle(seoData.title);

    this._meta.updateTag({ name: 'title', content: seoData.title });
    this._meta.updateTag({ name: 'description', content: seoData.description });
    this._meta.updateTag({ name: 'og:title', content: seoData.title });
    this._meta.updateTag({ name: 'og:description', content: seoData.description });
    this._meta.updateTag({ name: 'og:image', content: seoData.image ?? '' });
    this._meta.updateTag({ name: 'og:image:height', content: seoData.height ?? '' });
    this._meta.updateTag({ name: 'og:image:width', content: seoData.width ?? '' });
    this._meta.updateTag({ name: 'og:url', content: seoData.url ?? '' });

    if (this._isBrowser) {
      const canonical = document.querySelector("link[rel='canonical']");
      canonical?.setAttribute('href', seoData.url ?? SEO.home.url!);

      const alternate = document.querySelector("link[rel='alternate']");
      alternate?.setAttribute('href', seoData.url ?? SEO.home.url!);
    }
  }
}
