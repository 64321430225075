import { Component, Input } from '@angular/core';
import { ExchangeRate } from '../../../../api/api-checkout/models/rq-checkout-search';
import { IDynamicPackage } from '../../../../public/pages/home/models';

@Component({
	selector: 'app-package-card-v2',
	templateUrl: './package-card-v2.component.html',
	styleUrls: ['./package-card-v2.component.scss']
})
export class PackageCardV2Component {
	@Input() item: IDynamicPackage;

	getExchangeAmount(value: ExchangeRate | null, currentAmount?: number): number | null {
		if (value === null || !currentAmount) return null;
		return value.amount * currentAmount;
	}
}
