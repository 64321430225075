import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HOT_SALE_DATA } from '../../constants';
import { Discount, IHotSale } from '../../models';

interface Item {
  value: any;
  name: string;
}

@Component({
  selector: 'app-hot-sale-detail',
  templateUrl: './hot-sale-detail.component.html',
  styleUrls: ['./hot-sale-detail.component.scss'],
})
export class HotSaleDetailComponent implements OnInit {
  detail?: IHotSale;
  dateItems: Item[] = [];
  roomTypeItems: Item[] = [];

  passengersQty = 2;
  selectedDate: string = '';
  roomType: number = 0;
  price: number = 0;
  discount?: Discount;

  isLightboxVisible = false;
  selectedImage = '';

  protected _isBrowser = false;
  private _sectionId: number;

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _modal: NgbModal
  ) {
    if (isPlatformBrowser(this._platformId)) this._isBrowser = true;
  }

  ngOnInit(): void {
    if (this._isBrowser) {
      this._activatedRoute.paramMap.subscribe((paramMap) => {
        this._sectionId = Number.parseInt(paramMap.get('sectionId') ?? '0');
        if (paramMap.has('sectionId') && paramMap.has('id') && !Number.isNaN(this._sectionId)) {
          const section = HOT_SALE_DATA.find((data) => data.id === this._sectionId);
          const packageId = Number.parseInt(paramMap.get('id') ?? '0');
          if (section && !Number.isNaN(packageId)) {
            this.detail = section.items.find((item) => item.id === packageId);
            if (this.detail) {
              this.initializeData();
              return;
            }
          }
        }
        this._router.navigateByUrl('ofertas').then(() => console.warn('Invalid params'));
      });
    }
  }

  initializeData() {
    this.dateItems =
      this.detail?.dates.map((item) => {
        return {
          name: item.description,
          value: item.value,
        };
      }) ?? [];
    this.selectedDate = this.detail?.dates[0].value;
    this.roomTypeItems =
      this.detail?.roomType.map((item) => {
        return {
          name: item.description,
          value: item.value,
        };
      }) ?? [];
    this.roomType = this.detail?.roomType[0].value;
    this.price = Number.parseInt(this.detail?.price.replace(',', '') ?? '0', 10);
    this.discount = this.detail?.discount;
  }

  onShare() {
    if (this._isBrowser) {
      try {
        navigator
          .share({
            title: this.detail!.title,
            text: this.detail!.description,
            url: this._router.url,
          })
          .then(() => console.info('Contenido compartido exitosamente'))
          .catch((err) => console.error('Error al compartir', err));
      } catch (e) {
        console.warn('Web Share API no soportada en este navegador', e);
      }
    }
  }

  onRoomTypeChange(event: string) {
    const roomType = this.detail!.roomType.find((item) => item.description === event)!;
    if (roomType.priceWithDiscount) {
      this.price = roomType.price ?? 0;
      this.discount = {
        percent: this.detail?.discount?.percent ?? 0,
        newPrice: roomType.priceWithDiscount ?? 0,
        maxAmount: this.detail?.discount?.maxAmount ?? 0,
      };
    } else {
      this.price = roomType.price;
      this.discount = undefined;
    }
  }

  goToBooking() {
    this._router.navigate([
      '/booking/ofertas',
      this._sectionId,
      this.detail?.id,
      this.passengersQty,
      this.selectedDate,
      this.roomType,
    ]);
  }

  openContactModal() {
    if (this._isBrowser) {
      const message = `Quiero comprar este paquete: ${location.href} Brochure: ${this.detail?.pdfUrl}`;
      window.open(
        `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}&phone=51919294628`,
        '_blank'
      );
    }
  }

  onSelectImage(imgUrl: string) {
    this.selectedImage = imgUrl;
    this.isLightboxVisible = true;
  }

  redirectToPDF() {
    if (this._isBrowser) window.open(this.detail?.pdfUrl, '_blank');
  }
}
