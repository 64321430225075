import { Component, OnInit } from '@angular/core';
import { CryptoService } from 'src/app/Services/util/crypto.service';
import { toUp } from '../../../shared/utils';
import { SeoService } from 'src/app/public/services/seo/seo.service';
import { PATH_NAMES } from '../../../shared/constants';

@Component({
  selector: 'app-responsabilidad-social',
  templateUrl: './responsabilidad-social.component.html',
  styleUrls: ['./responsabilidad-social.component.scss']
})
export class ResponsabilidadSocialComponent implements OnInit {

  constructor(private _cryptoService: CryptoService, 		private _seoService: SeoService  ) { }

  ngOnInit(): void {
    const path  = PATH_NAMES.RESPONSIBILITY;
		this._seoService.setSEOByPath(path);

    let userID: string = '';
    let user_existingCustomer: boolean = false;
    const credentials = localStorage.getItem('usuario');
    const bookings = localStorage.getItem('bookings');

    if (credentials) {
      const credentialsJson = JSON.parse(credentials);
      userID = this._cryptoService.encrypt(credentialsJson.email);

      if (bookings)
        user_existingCustomer = JSON.parse(bookings).length > 0;
    }

    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: "user_info",
      userID: userID,
      user_existingCustomer: user_existingCustomer
    });

    (window as any).dataLayer.push({
      event: "virtualPageView",
      virtualPagePath: "/responsabilidad-social",
      virtualPageTitle: "NMV: Responsabilidad social"
    });

    toUp();
  }

}
