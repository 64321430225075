import { Component, Input, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { GlobalComponent } from '../../../global';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-input-class',
  templateUrl: './input-class.component.html',
  styleUrls: ['./input-class.component.scss']
})
export class InputClassComponent implements OnInit {
  @Input() isLeft=false;
  @Input() includeFirtClass=true;
  @Input() isSearchFlight=false;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
  showOptions=false;
  nameClass='Económica'
  indexClass=0;

	ngOnInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			if (window.location.href.includes('resultados')) {
				this.indexClass = GlobalComponent.searchData.flightClass;
				switch (this.indexClass) {
					case 0:
						this.nameClass = 'Económica';
						break;
					case 1:
						this.nameClass = 'Premium economy';
						break;
					case 2:
						this.nameClass = 'Ejecutiva/Business';
						break;
				}
			}
		}
	}

  clickOption(nameClass:string, index:number){
    this.nameClass=nameClass;
    this.indexClass=index;
    this.showOptions=false;
  }

  clickInside(){
    this.showOptions=!this.showOptions;
  }

  getValues(){
    return {
      flightClass: this.indexClass,
    }
  }

}
