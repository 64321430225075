export class StringUtils {
  static capitalizeWords(input: string): string {
    const words = input.split(' ');

    const capitalizeWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return capitalizeWords.join(' ');
  }

  static randomString(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static normalizeString(input: string): string {
    if (!input) return input;
    return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
}
