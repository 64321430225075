import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuccessDialogComponent } from './success-dialog.component';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
  declarations: [SuccessDialogComponent],
  imports: [CommonModule, PipesModule],
})
export class SuccessDialogModule {}
