import { NgModule } from '@angular/core';
import { InputComponent } from './input.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RemoveAccentsDirective } from '../../../directives/remove-accents.directive';
import { DirectivesModule } from '../../../directives/directives.module';

@NgModule({
	imports: [ CommonModule, FormsModule, ReactiveFormsModule, DirectivesModule ],
	exports: [InputComponent],
	declarations: [InputComponent, RemoveAccentsDirective],
	providers: []
})
export class InputModule {}
