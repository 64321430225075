import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NguCarouselModule } from '@ngu/carousel';
import { CarouselModule } from 'primeng/carousel';
import { GalleriaModule } from 'primeng/galleria';
import { AereolineasModule } from 'src/app/shared/components/moleculas/aereolineas/aereolineas.module';
import { ButtonModule } from '../../../../../../shared/components/moleculas/button/button.module';
import { CardPlaceholderModule } from '../../../../../../shared/components/moleculas/card-placeholder/card-placeholder.module';
import { CustomButtonsModule } from '../../../../../../shared/components/moleculas/custom-buttons/custom-buttons.module';
import { FilterTabsModule } from '../../../../../../shared/components/moleculas/filter-tabs/filter-tabs.module';
import { TabsModule } from '../../../../../../shared/components/moleculas/tabs/tabs.module';
import { MaterialModule } from '../../../../../../shared/material.module';
import { PipesModule } from '../../../../../../shared/pipes/pipes.module';
import { FlightModule } from '../flight/flight.module';
import { ActivityComponent } from './activity/activity.component';
import { CardDetailComponent } from './card-detail/card-detail.component';
import { DestinationComponent } from './destination/destination.component';
import { DestinosComponent } from './destinos.component';
import { EmailSubscriptionComponent } from './email-subscription/email-subscription.component';
import { FaqComponent } from './faq/faq.component';
import { HotelsComponent } from './hotels/hotels.component';
import { RoutesListComponent } from './routes-list/routes-list.component';

@NgModule({
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	declarations: [
		DestinosComponent,
		ActivityComponent,
		HotelsComponent,
		DestinationComponent,
		CardDetailComponent,
		EmailSubscriptionComponent,
		FaqComponent,
		RoutesListComponent
	],
	imports: [
		CommonModule,
		MaterialModule,
		TabsModule,
		CarouselModule,
		GalleriaModule,
		AereolineasModule,
		NguCarouselModule,
		FlightModule,
		CustomButtonsModule,
		PipesModule,
		ReactiveFormsModule,
		FilterTabsModule,
		ButtonModule,
		CardPlaceholderModule
	],
	exports: [DestinosComponent, DestinationComponent, FaqComponent, RoutesListComponent],
	providers: []
})
export class DestinosModule {}
