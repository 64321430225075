import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SuccessDialogModule } from '../../../shared/components/moleculas/success-dialog/success-dialog.module';
import { DirectivesModule } from '../../../shared/directives/directives.module';
import { PassengerComponent } from './passenger/passenger.component';
import { TusDatosComponent } from './tus-datos.component';


@NgModule({
	declarations: [TusDatosComponent, PassengerComponent],
	imports: [CommonModule, ReactiveFormsModule, MatCheckboxModule, SuccessDialogModule, DirectivesModule]
})
export class TusDatosModule {}
