import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { CustomAdapter } from 'src/app/Services/datepicker/customAdapter.service';
import { CustomDateParserFormatter } from 'src/app/Services/datepicker/customDateParserFormatter.service';
import { CustomDatepickerI18nService } from 'src/app/Services/datepicker/customDatepickerI18n.service';
import { MaterialModule } from '../../../material.module';
import { ButtonModule } from '../button/button.module';
import { CalendarEndDateModule } from '../calendar-enddate/calendar-enddate.module';
import { CalendarPriceModule } from '../calendar-price/calendar-price.module';
import { CalendarModule } from '../calendar/calendar.module';
import { InputAutocompleteModule } from '../input-autocomplete/input-autocomplete.module';
import { InputClassModule } from '../input-class/input-class.module';
import { InputPassengersModule } from '../input-passengers/input-passengers.module';
import { InputRangeModule } from '../input-range/input-range.module';
import { InputSearchFlightModule } from '../input-search-flight/input-search-flight.module';
import { MultivueloModule } from '../multivuelo/multivuelo.module';
import { NewCalendarModule } from '../new-calendar/new-calendar.module';
import { PopUpPasajerVuelosoModule } from '../pop-up-pasajero-vuelos/pop-up-pasajero-vuelos.module';
import { PopUpPasajeroModule } from '../pop-up-pasajero/pop-up-pasajero.module';
import { TabActividadesComponent } from './tab-actividades/tab-actividades.component';
import { TabArmaPaquetesComponent } from './tab-arma-paquetes/tab-arma-paquetes.component';
import { TabAutosComponent } from './tab-autos/tab-autos.component';
import { TabHotelComponent } from './tab-hotel/tab-hotel.component';
import { TabPaquetesComponent } from './tab-paquetes/tab-paquetes.component';
import { TabVueloHotelComponent } from './tab-vuelo-hotel/tab-vuelo-hotel.component';
import { TabVuelosMulticityComponent } from './tab-vuelos-multicity/tab-vuelos-multicity.component';
import { TabVuelosV2Component } from './tab-vuelos-v2/tab-vuelos-v2.component';
import { TabVuelosComponent } from './tab-vuelos/tab-vuelos.component';

@NgModule({
	declarations: [
		TabVueloHotelComponent,
		TabHotelComponent,
		TabActividadesComponent,
		TabVuelosComponent,
		TabArmaPaquetesComponent,
		TabPaquetesComponent,
		TabAutosComponent,
		TabVuelosMulticityComponent,
		TabVuelosV2Component
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PopUpPasajeroModule,
		MaterialModule,
		CalendarModule,
		CalendarEndDateModule,
		CalendarPriceModule,
		InputAutocompleteModule,
		PopUpPasajerVuelosoModule,
		NgbModule,
		InputSearchFlightModule,
		InputRangeModule,
		ButtonModule,
		InputClassModule,
		NewCalendarModule,
		InputPassengersModule,
		NgxMaskModule.forRoot(),
		MultivueloModule
	],
	providers: [
		MatSnackBar,
		{ provide: NgbDateAdapter, useClass: CustomAdapter },
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
		{ provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService }
	],
	exports: [
		TabVueloHotelComponent,
		TabHotelComponent,
		TabActividadesComponent,
		TabVuelosComponent,
		TabArmaPaquetesComponent,
		TabPaquetesComponent,
		TabAutosComponent,
		TabVuelosMulticityComponent,
		TabVuelosV2Component
	]
})
export class FilterTabsModule {}
