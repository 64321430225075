import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../moleculas/button/button.module';
import { CardPlaceholderModule } from '../moleculas/card-placeholder/card-placeholder.module';
import { CustomButtonsModule } from '../moleculas/custom-buttons/custom-buttons.module';
import { PackageCardV2Component } from './package-card-v2/package-card-v2.component';
import { PackageCardComponent } from './package-card/package-card.component';
import { SectionPackagesComponent } from './section-packages.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomButtonsModule,
    ButtonModule,
    CardPlaceholderModule,
  ],
  exports: [SectionPackagesComponent, PackageCardComponent, PackageCardV2Component],
  declarations: [SectionPackagesComponent, PackageCardComponent, PackageCardV2Component],
  providers: [],
})
export class SectionPackagesModule {}
