import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../../../../../Services/notification.service';
import { ETypeNotification } from '../../../../../constants';

@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
	isShow = false;
	title: string;
	message: string;
	typeNotification: ETypeNotification = ETypeNotification.ERROR;
	types = ETypeNotification;

	constructor(private readonly notificationService: NotificationService) {}

	ngOnInit(): void {
		this.notificationService.getNotification().subscribe((resp) => {
			this.typeNotification = resp.type;
			this.isShow = resp.show;
			this.title = resp.title;
			this.message = resp.message;

			setTimeout(() => {
				this.isShow = false;
			}, resp.timeSecond * 1000);
		});
	}
}
