import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { interval, Subject, Subscription } from 'rxjs';
import { filter, takeUntil, takeWhile } from 'rxjs/operators';
import { INotificationModel } from '../../../../public/Models/notifications/notification.model';
import { AccountsService, UserStorage } from '../../../../Services/accounts.service';
import { CookiesService } from '../../../../Services/cookies/cookies.service';
import { CookiesNamesEnum } from '../../../../Services/cookies/enums/cookies-names.enum';
import { FileService } from '../../../../Services/file.service';
import { PlatformService, ToolbarService } from '../../../services';
import { SeoService } from '../../../../public/services/seo/seo.service';
import { DEVICES } from '../../../constants';
import { ForgotPasswordComponent, LoginComponent, NewAccountComponent } from './modals';
import { NotificationsComponent } from './notifications';
import { HomeService } from '../../../../public/pages/home/services';
import { MobileComponent } from './mobile';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @ViewChild('dropdown') dropdownEl: ElementRef;
  @ViewChild('notifications') notificationsEl: ElementRef;

  showAdvertisement = false;
  advertisementContent: string;
  isBookingPage = false;
  isResultsPage = false;
  showsNotifications = false;
  notificationsList: INotificationModel[] = [];
  isFromIOS = false;

  isLoggedIn = false;
  userStorage: UserStorage;
  showsProfileOptions = false;
  unsubscribe$ = new Subject<any>();

  protected _isBrowser = false;
  private _unsubscribeModal$ = new Subject<void>();
  private _notificationsSubscription = new Subscription();
  private _showsLoginModalSubscription = new Subscription();
  private intervalSubscription = new Subscription();

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _router: Router,
    private _cookiesService: CookiesService,
    private _platformService: PlatformService,
    private _accountService: AccountsService,
    private _fileService: FileService,
    private _modal: NgbModal,
    private _bottomSheet: MatBottomSheet,
    private _seoService: SeoService,
    private _toolbarService: ToolbarService,
    private homeService: HomeService,
    private offCanvasService: NgbOffcanvas
  ) {
    this.isBookingPage = this._router.url.includes('booking');
    if (isPlatformBrowser(this._platformId)) {
      this._isBrowser = true;
      this.isFromIOS = this._platformService.getDevice() === DEVICES.IOS;
    }
  }

  ngOnInit(): void {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event: any) => {
        this.isBookingPage = event.url.toString().includes('booking');
        this.isResultsPage = event.url.toString().includes('resultados');
      });

    this.getAdvertisement();
    this.validateShowAd();
    this.loginValidation();
    this.subscribeToLoginModal();
  }

  private getAdvertisement() {
    this.homeService
      .getAdvertisement()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data) => {
          if (
            data?.result?.advertisementContent &&
            data?.result?.startDate &&
            data?.result?.endDate
          ) {
            const startDate = new Date(data.result.startDate);
            const endDate = new Date(data.result.endDate);
            const currentDate = new Date();

            if (currentDate >= startDate && currentDate <= endDate) {
              this.advertisementContent = data.result.advertisementContent;
              this.emitAdContent(this.advertisementContent);
              if (this._isBrowser) {
                this.intervalSubscription = interval(1000)
                  .pipe(
                    takeWhile(() => {
                      const now = new Date();
                      if (now > endDate) {
                        this.advertisementContent = '';
                        this.emitAdContent(this.advertisementContent);
                        return false;
                      }
                      return true;
                    })
                  )
                  .subscribe();
              }
              return;
            }
          }
          this.emitAdContent('');
        },
        error: () => this.emitAdContent(''),
      });
  }

  private validateShowAd() {
    this._toolbarService.showAdvertisement$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => (this.showAdvertisement = value));
  }

  private emitAdContent(content: string) {
    this._toolbarService.advertisementContent$.next(content);
  }

  private subscribeToLoginModal() {
    this._showsLoginModalSubscription = this._toolbarService.loginModal$.subscribe((opens) => {
      if (opens) this.openLoginModal();
    });
  }

  clickMobileMenu() {
    const offCanvasRef = this.offCanvasService.open(MobileComponent, {
      animation: true,
      panelClass: 'bg-white',
    });
    offCanvasRef.componentInstance.isLogged = this.isLoggedIn;
    offCanvasRef.closed
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) =>
        result && result.signIn ? this.logout() : this.openLoginModal()
      );
  }

  private loginValidation() {
    this._accountService.isLogged().subscribe((logged) => {
      this.isLoggedIn = logged;
      if (this._isBrowser && this.isLoggedIn) {
        this.userStorage = this._accountService.getUserStorage();
        !this.userStorage.image ? this.downloadImage(this.userStorage) : null;
        this.userStorage = this._accountService.getUserStorage();
      }
    });
  }

  private downloadImage(user: UserStorage) {
    this._fileService.getImage(user.id).subscribe((img) => {
      if (this._isBrowser) {
        this.userStorage.image = img;
        this._accountService.guardarImage(img);
      }
    });
  }

  @HostListener('document:click', ['$event'])
  blurRange(event: MouseEvent) {
    if (this._isBrowser) {
      if (this.dropdownEl && !this.dropdownEl.nativeElement.contains(event.target))
        this.showsProfileOptions = false;
      if (this.notificationsEl && !this.notificationsEl.nativeElement.contains(event.target))
        this.showsNotifications = false;
    }
  }

  openLoginModal() {
    if (this._isBrowser) {
      this.offCanvasService.dismiss();
      const loginModalRef = this._modal.open(LoginComponent, {
        backdrop: 'static',
      });
      loginModalRef.closed.pipe(takeUntil(this._unsubscribeModal$)).subscribe((result: any) => {
        if (this._isBrowser) {
          history.pushState('', document.title, window.location.pathname + location.search);
          if (result && !result.isLoggedIn && result.redirect)
            if (result.redirect === 'NEW_ACCOUNT') this.openNewAccountModal();
            else if (result.redirect === 'FORGOT_PASSWORD') this.openForgotPasswordModal();
        }
      });
    }
  }

  private openNewAccountModal() {
    if (this._isBrowser) {
      const newAccountModalRef = this._modal.open(NewAccountComponent, {
        backdrop: 'static',
        windowClass: 'new-account-modal',
      });
      newAccountModalRef.closed
        .pipe(takeUntil(this._unsubscribeModal$))
        .subscribe((result: any) => {
          if (result && result.openLogin) this.openLoginModal();
        });
    }
  }

  openForgotPasswordModal() {
    if (this._isBrowser) {
      const forgotPasswordModalRef = this._modal.open(ForgotPasswordComponent, {
        windowClass: 'forgot-password-modal',
        centered: true,
      });
      forgotPasswordModalRef.closed
        .pipe(takeUntil(this._unsubscribeModal$))
        .subscribe((result: any) => {
          if (result && result.openLogin) this.openLoginModal();
        });
    }
  }

  openNotificationsMobile() {
    if (this._isBrowser) {
      const bottomSheetRef = this._bottomSheet.open(NotificationsComponent, {
        data: { notificationList: this.notificationsList },
      });

      this._notificationsSubscription = bottomSheetRef
        .afterDismissed()
        .subscribe((reason: { notification: INotificationModel }) => {
          if (!reason || !reason.notification) return;
          this.redirectFromNotification(
            reason.notification.url,
            this.notificationsList.findIndex((notification) => notification === reason.notification)
          );
        });
    }
  }

  redirectFromNotification(url: string, index: number) {
    this.removeNotificationByIndex(index);
    this._router.navigateByUrl(url).then(() => null);
  }

  private removeNotificationByIndex(index: number) {
    this.notificationsList.splice(index, 1);
    this._cookiesService.setCookie(
      CookiesNamesEnum.NOTIFICATIONS,
      JSON.stringify(this.notificationsList)
    );
  }

  logout() {
    this._accountService.signOut();
    this.showsProfileOptions = false;
    this.offCanvasService.dismiss();
    this._router.navigateByUrl('/').then(() => null);
  }

  ngOnDestroy() {
    this._unsubscribeModal$.next();
    this._unsubscribeModal$.complete();
    this._notificationsSubscription.unsubscribe();
    this._showsLoginModalSubscription.unsubscribe();
    this.intervalSubscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
