import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterResultComponent } from './filter-result.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MaterialModule } from '../../../material.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [FilterResultComponent],
  imports: [
    CommonModule,
    MaterialModule,
    NgxSliderModule
  ],
  exports: [FilterResultComponent],

})
export class FilterResultModule { }
