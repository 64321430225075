export const TAB_INDEXES = {
  FLIGHTS: 0,
  HOTELS: 1,
  FLIGHT_HOTEL: 2,
  TOURIST_PACKAGES: 3,
  INSURANCE: 4,
  HOT_SALE: 5,
} as const;

export const TABS_DATA = [
  {
    icon: './assets/tabs/plane.svg',
    name: 'Vuelos',
    active: './assets/tabs/plane-red.svg',
    path: 'vuelos',
    navigates: true,
  },
  {
    icon: './assets/tabs/room.svg',
    name: 'Hoteles',
    active: './assets/tabs/room-red.svg',
    path: 'hoteles',
    navigates: true,
  },
  {
    icon: './assets/tabs/hotel.svg',
    name: 'Vuelo +&nbsp;hotel',
    active: './assets/tabs/hotel-red.svg',
    path: 'vuelohotel',
    navigates: true,
  },
  {
    icon: './assets/tabs/bag.svg',
    name: 'Paquetes',
    active: './assets/tabs/bag-red.svg',
    path: 'paquetes',
    navigates: true,
  },
  {
    icon: './assets/tabs/secure.svg',
    name: 'Seguros',
    active: './assets/tabs/secure-red.svg',
    path: 'seguro-viaje',
    navigates: true,
  },
  /*{
    icon: './assets/tabs/hot-sale.svg',
    name: 'OFERTAS',
    active: './assets/tabs/hot-sale.svg',
    path: 'ofertas',
    navigates: true,
  },*/
] as const;
