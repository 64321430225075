import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutlineButtonComponent } from './outline-button/outline-button.component';
import { FilledButtonComponent } from './filled-button/filled-button.component';
import { RouterLink, RouterLinkWithHref } from '@angular/router';
import { LinkButtonComponent } from './link-button/link-button.component';


@NgModule({
  declarations: [FilledButtonComponent, OutlineButtonComponent, LinkButtonComponent],
  exports: [FilledButtonComponent, OutlineButtonComponent, LinkButtonComponent],
  imports: [CommonModule, RouterLink, RouterLinkWithHref],
})
export class CustomButtonsModule {}
