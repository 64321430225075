import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IDynamicPackage } from '../../public/pages/home/models';

@Injectable({
	providedIn: 'root'
})
export class PackageCardService {
	packageCardData$: BehaviorSubject<IDynamicPackage[]> = new BehaviorSubject<IDynamicPackage[]>([]);

	constructor() {}

	setPackageCardData(data: IDynamicPackage[]): void {
		this.packageCardData$.next(data);
	}

	getPackageCardData(): BehaviorSubject<IDynamicPackage[]> {
		return this.packageCardData$;
	}

	clearPackageCardData(): void {
		this.packageCardData$.next([]);
	}
}
