import { IHotSale } from '../models';

export const HOT_SALE_DATA = [
	{
		id: 1,
		title: 'Cyber Days con NM Viajes',
		items: [
			{
				id: 17,
				title: 'Año Nuevo - Punta Cana 5N con SKY + Equipaje "28dic al 02ene"',
				imgUrl: 'https://cdn5.travelconline.com/images/fit-in/500x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FWjpzvtxUiv8B2JNzYO0JurjT.jpg',
				days: 6,
				nights: 5,
				origin: 'Lima (LIM)',
				destination: 'Punta Cana',
				tag: '',
				airline: 'SKY',
				price: '1,692',
				departureDate: '28/12/2024',
				returnDate: '02/01/2025',
				bannerUrl: 'https://cdn5.travelconline.com/images/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2Fn8UsK65gWchxLGiEBu-BrzldrpZDOCquCT.png',
				description: 'Punta Cana, República Dominicana',
				itinerary: [
					{
						day: '28',
						month: 'dic',
						description: '<b>Lima,</b> Perú.<br><b>Transporte:</b> Desde Jorge Chavez Intl (LIM) a Punta Cana Intl - PUJ.'
					},
					{
						day: '28',
						month: 'dic',
						description: '<b>Punta Cana,</b> República Dominicana.<br>' +
								'<b>Traslado:</b> de Punta Cana Intl (PUJ) a Bahia Principe Grand Turquesa - All Inclusive.<br>' +
								'<b>Alojamiento:</b> Bahia Principe Grand Turquesa - All Inclusive.<br>'
					},
					{
						day: '02',
						month: 'ene',
						description: '<b>Traslado:</b> de Bahia Principe Grand Turquesa - All Inclusive a Punta Cana Intl (PUJ).<br>' +
								'<b>Transporte:</b> Desde Punta Cana Intl a Jorge Chavez Intl.<br>' +
								'<b>Lima,</b> Perú.'
					}
				],
				includes: {
					transport: 1,
					accommodation: 1,
					insurances: 1,
					transfers: 2,
					allInclusive: 5,
					baggage: 1
				},
				important: [
					{ description: 'Internacional - Seguro (Assist Card: Tarjeta de Asistencia (Cobertura de $30,000)) - Región (Solo continente) - Días (6)' },
					{
						description: 'PERU: // El impuesto de servicio lo determinara cada hotel en sus vouchers y en algunos casos se tiene que pagar en destino. ' +
								'// El impuesto del 18% es obligatorio para todos los hoteles y no está incluido en las tarifas, los pasajeros peruanos o con residencia de 90 días o más deben pagar el impuesto de acuerdo a la ley.'
					}
				],
				hotelFees: 'Tener en cuenta que en algunos destinos los impuestos hoteleros y locales no están incluidos en la tarifa y se tienen que pagar a la llegada.',
				serviceFees: '',
				maxPassengers: 2,
				dates: [ { value: '28/12/2024', description: 'sábado 28 de diciembre 2024' } ],
				roomType: [ { value: 1, description: 'Junior suit superior', price: 1641 } ],
				gallery: [
					'https://cdn5.travelconline.com/images/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FISbckvTTiv5jGhJhogSI2gjH.png',
					'https://cdn5.travelconline.com/images/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FJ1h2khoh957fsFgbMTtnQOSV.png',
					'https://cdn5.travelconline.com/images/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FISHqRLzDjGcP920htjXRjF77.png',
					'https://cdn5.travelconline.com/images/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FsUXuE7bjnPbH3oK8N3gs3JHV.png'
				],
				pdfUrl: 'https://nm-viajes.paquetedinamico.com/brochurePDF?ideaId=14664934&lang=es&currency=USD&agencyId=162512&userId=nmviajes-sabad'
			},
			{
				id: 18,
				title: 'Año Nuevo - Punta Cana 5N con SKY + Equipaje "29dic al 03ene"',
				imgUrl: 'https://cdn5.travelconline.com/images/fit-in/500x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2F5hMhSfK7XrC2-cZhtelKBhzjpeg.jpeg',
				days: 6,
				nights: 5,
				origin: 'Lima (LIM)',
				destination: 'Punta Cana',
				tag: '',
				airline: 'SKY',
				price: '1,641',
				departureDate: '29/12/2024',
				returnDate: '03/01/2025',
				bannerUrl: 'https://cdn5.travelconline.com/images/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2Fn8UsK65gWchxLGiEBu-BrzldrpZDOCquCT.png',
				description: 'Punta Cana, República Dominicana',
				itinerary: [
					{
						day: '29',
						month: 'dic',
						description: '<b>Lima,</b> Perú.<br><b>Transporte:</b> Desde Jorge Chavez Intl (LIM) a Punta Cana Intl - PUJ.'
					},
					{
						day: '29',
						month: 'dic',
						description: '<b>Punta Cana,</b> República Dominicana.<br>' +
								'<b>Traslado:</b> de Punta Cana Intl (PUJ) a Bahia Principe Grand Turquesa - All Inclusive.<br>' +
								'<b>Alojamiento:</b> Bahia Principe Grand Turquesa - All Inclusive.<br>'
					},
					{
						day: '03',
						month: 'ene',
						description: '<b>Traslado:</b> de Bahia Principe Grand Turquesa - All Inclusive a Punta Cana Intl (PUJ).<br>' +
								'<b>Transporte:</b> Desde Punta Cana Intl a Jorge Chavez Intl.<br>' +
								'<b>Lima,</b> Perú.'
					}
				],
				includes: {
					transport: 1,
					accommodation: 1,
					insurances: 1,
					transfers: 2,
					allInclusive: 5,
					baggage: 1
				},
				important: [
					{ description: 'Internacional - Seguro (Assist Card: Tarjeta de Asistencia (Cobertura de $30,000)) - Región (Solo continente) - Días (6)' },
					{
						description: 'PERU: // El impuesto de servicio lo determinara cada hotel en sus vouchers y en algunos casos se tiene que pagar en destino. ' +
								'// El impuesto del 18% es obligatorio para todos los hoteles y no está incluido en las tarifas, los pasajeros peruanos o con residencia de 90 días o más deben pagar el impuesto de acuerdo a la ley.'
					}
				],
				hotelFees: 'Tener en cuenta que en algunos destinos los impuestos hoteleros y locales no están incluidos en la tarifa y se tienen que pagar a la llegada.',
				serviceFees: '',
				maxPassengers: 2,
				dates: [ { value: '29/12/2024', description: 'domingo 29 de diciembre 2024' } ],
				roomType: [ { value: 1, description: 'Junior suit superior', price: 1641 } ],
				gallery: [
					'https://cdn5.travelconline.com/images/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FISbckvTTiv5jGhJhogSI2gjH.png',
					'https://cdn5.travelconline.com/images/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FJ1h2khoh957fsFgbMTtnQOSV.png',
					'https://cdn5.travelconline.com/images/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FISHqRLzDjGcP920htjXRjF77.png',
					'https://cdn5.travelconline.com/images/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fhotel-images%2FsUXuE7bjnPbH3oK8N3gs3JHV.png'
				],
				pdfUrl: 'https://nm-viajes.paquetedinamico.com/brochurePDF?ideaId=14199754&lang=es&currency=USD&agencyId=162512&userId=nmviajes-sabad'
			},
			{
				id: 19,
				title: 'Cartagena 3N con LATAM + Bolso de mano "13 al 16feb"',
				imgUrl: 'https://cdn5.travelconline.com/images/fit-in/500x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FduW3wtRV6OxNyGebA2Xgv3bb.jpg',
				days: 4,
				nights: 3,
				origin: 'Lima (LIM)',
				destination: 'Cartagena',
				tag: '',
				airline: 'LATAM',
				price: '598',
				departureDate: '13/02/2025',
				returnDate: '16/02/2025',
				bannerUrl: 'https://cdn5.travelconline.com/images/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fstatic.travelconline.com%2Fimagenes%2FCIqhE6okCcYv-tOuhh07zxaKcPOp.jpeg',
				description: 'Cartagena, Colombia',
				itinerary: [
					{
						day: '13',
						month: 'feb',
						description: '<b>Lima,</b> Perú.<br><b>Transporte:</b> Desde Jorge Chavez Intl (LIM) a Rafael Nunez (CTG).'
					},
					{
						day: '13',
						month: 'feb',
						description: '<b>Cartagena de Indias,</b> Colombia.<br>' +
								'<b>Traslado:</b> de Rafael Nunez (CTG) a Atlantic Lux Hotel.<br>' +
								'<b>Alojamiento:</b> Atlantic Lux Hotel.<br>'
					},
					{
						day: '16',
						month: 'feb',
						description: '<b>Traslado:</b> de Atlantic Lux Hotel a Rafael Nunez (CTG).<br>' +
								'<b>Transporte:</b> Desde Rafael Nunez a Jorge Chavez Intl.<br>' +
								'<b>Lima,</b> Perú.'
					}
				],
				includes: {
					transport: 1,
					accommodation: 1,
					insurances: 1,
					transfers: 2,
					breakfast: 3,
					baggage: 1
				},
				important: [
					{ description: 'Seguro de Viajes: Internacional - Seguro (Assist Card: Tarjeta de Asistencia (Cobertura de $30,000)) - Región (Solo continente) - Días (4)' },
					{
						description: 'PERU: // El impuesto de servicio lo determinara cada hotel en sus vouchers y en algunos casos se tiene que pagar en destino. ' +
								'// El impuesto del 18% es obligatorio para todos los hoteles y no está incluido en las tarifas, los pasajeros peruanos o con residencia de 90 días o más deben pagar el impuesto de acuerdo a la ley.'
					}
				],
				hotelFees: 'Tener en cuenta que en algunos destinos los impuestos hoteleros y locales no están incluidos en la tarifa y se tienen que pagar a la llegada.',
				serviceFees: '',
				maxPassengers: 2,
				dates: [ { value: '13/02/2025', description: 'jueves 13 de febrero 2025' } ],
				roomType: [ { value: 1, description: 'Doble estándar', price: 598 } ],
				gallery: [
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F6000000%2F5230000%2F5227800%2F5227749%2F2255bf64_z.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F6000000%2F5230000%2F5227800%2F5227749%2F9e228b6b_z.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F6000000%2F5230000%2F5227800%2F5227749%2F468ab88c_z.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F6000000%2F5230000%2F5227800%2F5227749%2F7fc18d05_z.jpg'
				],
				pdfUrl: 'https://nm-viajes.paquetedinamico.com/brochurePDF?ideaId=12889720&lang=es&currency=USD&agencyId=162512&userId=nmviajes-sabad'
			},
			{
				id: 20,
				title: 'Cartagena 3N con LATAM + Bolso de mano "20 al 23feb"',
				imgUrl: 'https://cdn5.travelconline.com/images/fit-in/500x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Ftr2storage.blob.core.windows.net%2Fimagenes%2FkRyM4ZlnDL4A-xNVhcyBtS13DtYe.jpeg',
				days: 4,
				nights: 3,
				origin: 'Lima (LIM)',
				destination: 'Cartagena',
				tag: '',
				airline: 'LATAM',
				price: '600',
				departureDate: '20/02/2025',
				returnDate: '23/02/2025',
				bannerUrl: 'https://cdn5.travelconline.com/images/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fstatic.travelconline.com%2Fimagenes%2FCIqhE6okCcYv-tOuhh07zxaKcPOp.jpeg',
				description: 'Cartagena, Colombia',
				itinerary: [
					{
						day: '20',
						month: 'feb',
						description: '<b>Lima,</b> Perú.<br><b>Transporte:</b> Desde Jorge Chavez Intl (LIM) a Rafael Nunez (CTG).'
					},
					{
						day: '20',
						month: 'feb',
						description: '<b>Cartagena,</b> Colombia.<br>' +
								'<b>Traslado:</b> de Rafael Nunez (CTG) a Atlantic Lux Hotel.<br>' +
								'<b>Alojamiento:</b> Atlantic Lux Hotel.<br>'
					},
					{
						day: '23',
						month: 'feb',
						description: '<b>Traslado:</b> de Atlantic Lux Hotel a Rafael Nunez (CTG).<br>' +
								'<b>Transporte:</b> Desde Rafael Nunez a Jorge Chavez Intl.<br>' +
								'<b>Lima,</b> Perú.'
					}
				],
				includes: {
					transport: 1,
					accommodation: 1,
					insurances: 1,
					transfers: 2,
					breakfast: 3,
					baggage: 1
				},
				important: [
					{ description: 'Seguro de Viajes: Internacional - Seguro (Assist Card: Tarjeta de Asistencia (Cobertura de $30,000)) - Región (Solo continente) - Días (4)' },
					{
						description: 'PERU: // El impuesto de servicio lo determinara cada hotel en sus vouchers y en algunos casos se tiene que pagar en destino. ' +
								'// El impuesto del 18% es obligatorio para todos los hoteles y no está incluido en las tarifas, los pasajeros peruanos o con residencia de 90 días o más deben pagar el impuesto de acuerdo a la ley.'
					}
				],
				hotelFees: 'Tener en cuenta que en algunos destinos los impuestos hoteleros y locales no están incluidos en la tarifa y se tienen que pagar a la llegada.',
				serviceFees: '',
				maxPassengers: 2,
				dates: [ { value: '20/02/2025', description: 'jueves 20 de febrero 2025' } ],
				roomType: [ { value: 1, description: 'Doble estándar', price: 600 } ],
				gallery: [
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F6000000%2F5230000%2F5227800%2F5227749%2F2255bf64_z.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F6000000%2F5230000%2F5227800%2F5227749%2F9e228b6b_z.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F6000000%2F5230000%2F5227800%2F5227749%2F468ab88c_z.jpg',
					'https://cdn5.travelconline.com/unsafe/fit-in/2000x0/filters:quality(75):strip_metadata():format(webp)/https%3A%2F%2Fi.travelapi.com%2Flodging%2F6000000%2F5230000%2F5227800%2F5227749%2F7fc18d05_z.jpg'
				],
				pdfUrl: 'https://nm-viajes.paquetedinamico.com/brochurePDF?ideaId=12890290&lang=es&currency=USD&agencyId=162512&userId=nmviajes-sabad'
			}
		] as IHotSale[]
	}
] as const;