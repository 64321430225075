import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { MaterialModule } from '../../../material.module';
import { ButtonModule } from '../button/button.module';
import { InputRangeModule } from '../input-range/input-range.module';
import { FiltersafeComponent } from './filtersafe.component';
import { CustomButtonsModule } from '../custom-buttons/custom-buttons.module';
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [FiltersafeComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    ButtonModule,
    InputRangeModule,
    CustomButtonsModule,
  ],
  exports: [FiltersafeComponent],
  bootstrap: [FiltersafeComponent],
})
export class FiltersafeModule {}
