import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DollarChangeService {
  constructor(private http: HttpClient) {}

  changeDollar(payload: any) {
    const url_api = `${environment.url_api}generales/TipoCambio`;
    return this.http
      .post<any>(url_api, payload, { observe: 'response' })
      .pipe(map((observe: any) => observe['body']['Resultado']));
  }
}
