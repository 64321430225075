import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ISeoFaq, ISeoFaqStatus } from '../destino.models';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  animations: [
    trigger('openClose', [
      transition('void => *', [
        style({ height: '0px', opacity: 0 }),
        animate('300ms ease-in-out', style({ height: '*', opacity: 1 })),
      ]),
      transition('* => void', [
        style({ height: '*' }),
        animate('300ms ease-in-out', style({ height: '0px', opacity: 0 })),
      ]),
    ]),
  ],
})
export class FaqComponent implements OnInit {
  _faqItems: ISeoFaqStatus[];
  @Input() set faqItems(items: ISeoFaq[]) {
    this._faqItems = items.map((item) => {
      return {
        ...item,
        status: false,
      };
    });
  }

  get faqItems(): ISeoFaqStatus[] {
    return this._faqItems;
  }
  constructor() {}

  ngOnInit(): void {}
}
