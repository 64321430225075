import { animate, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { AccountsService } from 'src/app/Services/accounts.service';
import { DataPagePresenterService } from 'src/app/Services/presenter/data-page-presenter.service';
import { TokenService } from '../../../../../api/api-nmviajes/services/token.service';
import { DestinyService } from '../../../../../Services/destiny/destiny.service';
import { DataLayerService, ToolbarService } from '../../../../../shared/services';
import { VerifiedUserComponent } from '../../components';
import { HomeService } from '../../services';

@Component({
  selector: 'app-home',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition('void => *', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
      transition('* => void', [style({ opacity: 0 })]),
    ]),
  ],
})
export class HomePageComponent implements OnInit, OnDestroy {
  destiny: any = [];
  destinyString: any;

  indexSelectedTab = 0;
  isBrowser: boolean = false;

  private _unsubscribe$ = new Subject<void>();

  constructor(
    public dataPagePresenterService: DataPagePresenterService,
    @Inject(PLATFORM_ID) private readonly _platformId: Object,
    private readonly _homeService: HomeService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router,
    private readonly _accountsService: AccountsService,
    private readonly _modal: NgbModal,
    private readonly _dataLayerService: DataLayerService,
    private readonly _toolbarService: ToolbarService,
    private readonly _tokenService: TokenService,
    private readonly flightSearchService: DestinyService
  ) {
    if (isPlatformBrowser(this._platformId)) this.isBrowser = true;
    this.setTabFromPath();
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      this._activatedRoute.params.subscribe((product) => {
        this._dataLayerService.sendUserInfo();
        this._dataLayerService.sendVirtualPage(`/confirmacion/${product.tab}`, 'NMV: Confirmacion');
      });
      this._router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => this.setTabFromPath());
      this.getConfirmation();
      this.removeStoredFilters();
    }
    this.getToken();
    this.flightSearchService.setOrigin('LIM', 'Lima', 'Perú');
  }

  private getToken() {
    this._tokenService
      .getAndSaveToken('Chrome')
      .pipe(take(1))
      .subscribe(() => console.info('INITIALIZING'));
  }

  private setTabFromPath() {
    const path = this._router.url.replace('/', '').trim();
    this.indexSelectedTab = this._homeService.setSelectedTabFromPath(path);
  }

  getSelectedTab(index: number) {
    if (this.isBrowser) {
      this.indexSelectedTab = index;
      this._homeService.setUrlPathFromTabIndex(this.indexSelectedTab);
    }
  }

  private getConfirmation() {
    this._activatedRoute.params
      .pipe(
        filter((params) => params.id),
        switchMap((param) => this._accountsService.confirmationAccount(param.id))
      )
      .subscribe((resp) => {
        if (resp.IsSuccess) {
          this._accountsService.dispatchConfirmate(true);
          this.toggleConfirmationModal();
        }
      });
  }

  private toggleConfirmationModal() {
    const modalRef = this._modal.open(VerifiedUserComponent, {
      windowClass: 'ModalUsuarioVerificado',
      ariaLabelledBy: 'ModalUsuarioVerificadoLabel',
      backdrop: false,
    });

    modalRef.closed.pipe(take(1)).subscribe(() => {
      this._router.navigateByUrl('/').then(() => null);
      this._toolbarService.openLoginModal();
    });
  }

  private removeStoredFilters() {
    localStorage.removeItem('filters');
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
    this._modal.dismissAll();
  }
}
