import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { Search } from 'src/app/api/api-nmviajes/models/ce-metasearch';
import { AccountsService } from 'src/app/Services/accounts.service';
import { NotificationService } from 'src/app/Services/notification.service';
import { GlobalComponent } from 'src/app/shared/global';
import { ISearchHistoryModel } from '../../../../../public/Models/search-history/search-history.model';
import { CookiesService } from '../../../../../Services/cookies/cookies.service';
import { CookiesNamesEnum } from '../../../../../Services/cookies/enums/cookies-names.enum';
import { StringUtils } from '../../../../utils/string-utils';
import { InputClassComponent } from '../../input-class/input-class.component';
import { InputPassengersComponent } from '../../input-passengers/input-passengers.component';
import { InputRangeComponent } from '../../input-range/input-range.component';
import { InputSearchFlightComponent } from '../../input-search-flight/input-search-flight.component';

@Component({
  selector: 'app-tab-vuelos-v2',
  templateUrl: './tab-vuelos-v2.component.html',
  styleUrls: ['./tab-vuelos-v2.component.scss'],
})
export class TabVuelosV2Component implements OnInit {
  constructor(
    private _notification: NotificationService,
    private _accountService: AccountsService,
    private _cookiesService: CookiesService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) {}

  @ViewChild('sliderRadios', { static: false }) sliderRadios: ElementRef;
  @ViewChild('childPassengers') childPassengers!: InputPassengersComponent;
  @ViewChild('childClass') childClass!: InputClassComponent;
  @ViewChild('childInputs') childInputs!: InputSearchFlightComponent;
  @ViewChild('childDates') childDates!: InputRangeComponent;
  @Output() reloadPageResult = new EventEmitter();

  scrollLeft: number;
  typeFlight = 0;
  dateRangeStartDate: NgbDate | null = null;
  isBrowser: Boolean = false;

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
    }
    if (this.isBrowser) {
      if (window.location.href.includes('resultados')) {
        this.typeFlight = GlobalComponent.searchData.flightType ?? 0;
      }
    }
  }

  counterSearch = 0;

  onDateRangeChanged(event: any) {
    // console.log(event);
    this.dateRangeStartDate = event.fromDate;
  }

  search() {
    if (this.isBrowser) {
      this.counterSearch++;
      const valuesPassengers = this.childPassengers.getValues();
      const valuesClass = this.childClass.getValues();
      const valuesInputs = this.childInputs.getValues();
      const valuesDates = this.childDates.getValues();
      console.log(valuesDates);

      const errors = [];

      if (!valuesInputs.arrivalLocation) errors.push('El destino es requerido');
      if (!valuesInputs.departureLocation) errors.push('La salida es requerido');
      if (valuesDates.departureDate == '') errors.push('La fecha de salida es requerido');
      if (valuesDates.arrivalDate == '' && this.typeFlight == 0)
        errors.push('La fecha de llegada es requerido');
      if (errors.length > 0) {
        window.scroll({ top: 0, behavior: 'smooth' });
        this._notification.showNotificacion(
          'Datos obligatorios sin completar',
          errors.join(' - '),
          7
        );
      } else {
        const route = this.getRoute({
          ...valuesClass,
          ...valuesPassengers,
          ...valuesInputs,
          ...valuesDates,
        });
        localStorage.setItem('searchParams', route);

        const arrivalDecoded = valuesInputs
          .arrivalLocation!.split('%20')
          .map((str) => StringUtils.capitalizeWords(str.replace(',', '')));
        const historyItem: ISearchHistoryModel = {
          code: arrivalDecoded[0],
          name: arrivalDecoded[1],
          country: arrivalDecoded[2],
        };

        let searchHistory: ISearchHistoryModel[] =
          this._cookiesService.getCookie(CookiesNamesEnum.SEARCH_HISTORY, true) ?? [];
        const arrivalDuplicated = searchHistory.find((item) => item.code === historyItem.code);

        if (!arrivalDuplicated) {
          searchHistory.unshift(historyItem);
          searchHistory = searchHistory.slice(0, 3);

          this._cookiesService.setCookie(
            CookiesNamesEnum.SEARCH_HISTORY,
            JSON.stringify(searchHistory),
            30
          );
        }

        this.router.navigateByUrl(route).then(() => null);
        this.reloadPageResult.emit();
      }
    }
  }

  getRoute(data: Search) {
    const random = '?rand=' + Math.round(Math.random() * 10000000000);
    const userStorage = this._accountService.getUserStorage();
    const email = userStorage.email || '';
    return `/resultados${random}&departureLocation=${data.departureLocation}&arrivalLocation=${data.arrivalLocation}&departureDate=${data.departureDate}&arrivalDate=${data.arrivalDate}&adults=${data.adults}&children=${data.children}&infants=${data.infants}&flightType=${this.typeFlight}&flightClass=${data.flightClass}&lang=ES&email=${email}`;
  }

  searchDataMulti($event: any) {
    if (this.isBrowser) {
      this.counterSearch++;
      const errors = [];

      $event.forEach((item: any) => {
        item = { ...item };
      });

      if ($event.some((item: any) => !item.departureLocation))
        errors.push('La salidas son requeridos');
      if ($event.some((item: any) => !item.arrivalLocation))
        errors.push('Los destinos son requeridos');
      if ($event.some((item: any) => item.departureDate == ''))
        errors.push('Las fechas de salidas son requeridos');

      if (errors.length > 0) {
        window.scroll({ top: 0, behavior: 'smooth' });
        this._notification.showNotificacion(
          'Datos obligatorios sin completar',
          errors.join(' - '),
          7
        );
      } else this.getRouteMulti($event);
    }
  }

  getRouteMulti(json: Search[]) {
    const valuesPassengers = this.childPassengers.getValues();
    const valuesClass = this.childClass.getValues();
    const dataGral = { ...valuesPassengers, ...valuesClass };

    const random = '?rand=' + Math.round(Math.random() * 10000000000);
    const userStorage = this._accountService.getUserStorage();
    const email = userStorage.email || '';

    const route = `/resultados${random}&adults=${dataGral.adults}&children=${dataGral.children}&infants=${
      dataGral.infants
    }&selected_cabins=&excludedAirlines=null&multicity=null&json=${JSON.stringify(
      json
    )}&email=${email}&flightType=2&flightClass=${dataGral.flightClass}`;
    localStorage.setItem('searchParams', route);
    this.router.navigateByUrl(route);
    this.reloadPageResult.emit();
  }

  changeType(index: number) {
    this.typeFlight = index;
  }
}
