import { Component, Input, OnInit } from '@angular/core';
import { StringUtils } from '../../../utils/string-utils';

@Component({
  selector: 'app-new-card-offer',
  templateUrl: './new-card-offer.component.html',
  styleUrls: ['./new-card-offer.component.scss'],
})
export class NewCardOfferComponent implements OnInit {
  @Input() title = '';
  @Input() price = 0;
  @Input() description = '';
  @Input() urlImage = '';
  @Input() numNight = 0;
  @Input() numStart = 0;
  @Input() destinationCode = '';
  @Input() destinationName = '';
  @Input() exchange = 0;

  constructor() {}

  ngOnInit(): void {}

  getNavigationUrl(): string {
    const slug = StringUtils.normalizeString(this.destinationName)
      .split(' ')
      .join('-')
      .toLowerCase();
    return `/vuelos/vuelos-a-${slug}`;
  }

  getExchangeAmount(value: number | null, currentAmount?: number): number | null {
    if (!value || !currentAmount) return null;
    return value * currentAmount;
  }
}
