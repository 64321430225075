import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToolbarService {
  private _loginModal = new BehaviorSubject<boolean>(false);
  loginModal$ = this._loginModal.asObservable();

  advertisementContent$ = new Subject<string>();
  showAdvertisement$ = new Subject<boolean>();

  constructor() {}

  openLoginModal() {
    this._loginModal.next(true);
  }
}
