import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class MotorVuelosInterceptor implements HttpInterceptor {
	constructor() {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.url.startsWith(environment.urlApiMotorVuelos) && !!environment.ocpApimSubscr) {
			const modifiedReq = req.clone({
				setHeaders: {
					'Ocp-Apim-Subscription-Key': environment.ocpApimSubscr
				}
			});
			return next.handle(modifiedReq);
		}

		return next.handle(req);
	}
}
